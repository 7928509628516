"use client";
/* eslint-disable @next/next/no-img-element */

import { MotionDiv } from "@/components/atom/motion";
import { toastSuccess } from "@/components/ToastComponent";
import { Button } from "@/components/ui/button";
import { useCart } from "@/hooks/useCart";
import { DEFAULT_DISCOUNT_PERC } from "@/lib/constants";
import { discountPrice, formatCurrency } from "@/lib/helper";
import { Stock } from "@/types";
import { ShoppingCart } from "@phosphor-icons/react";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";

type Props = {
  item: Stock;
};

export const Product = ({
  item,
  onCheckout,
  onAddToCart,
  extra,
}: {
  item: Stock;
  onCheckout?: (item: Stock) => void;
  onAddToCart?: (item: Stock) => void;
  extra?: React.ReactElement;
}) => {
  return (
    <MotionDiv
      className="md:max-w-sm w-full p-6 bg-white rounded-3xl h-full flex flex-col justify-between"
      layoutId={`item-${item.model}`}
    >
      <div>
        <div className="flex items-center justify-between">
          <Image
            width={80}
            height={80}
            src={item.iconUrl!}
            alt={item.model}
            className="object-fit h-20 w-20 rounded-lg"
          />
          <div className="text-right">
            <div className="text-sm text-gray-500 line-through">
              {formatCurrency(discountPrice(item.price, DEFAULT_DISCOUNT_PERC))}
            </div>
            <div className="text-2xl font-bold tracking-tight">
              {formatCurrency(item.price)}
            </div>
          </div>
        </div>

        {onCheckout && onAddToCart && (
          <div className="flex gap-3 my-8">
            <Link
              className="w-full"
              href={`/shop/${item.model
                .split("_")[0]
                .toLocaleLowerCase()}/${item.model.toLowerCase()}`}
              aria-label="Mua ngay"
              onClick={() => onCheckout(item)}
              passHref
            >
              <Button
                variant="primary"
                className="w-full conv__click_buy_now"
                key={item.name}
              >
                Mua Ngay
              </Button>
            </Link>
            <Button
              aria-label="Thêm vào giỏ hàng"
              variant="outline"
              size="icon"
              className="rounded-full px-3 conv__click_add_to_cart"
              onClick={() => {
                onAddToCart(item);
                toastSuccess(`Đã thêm ${item.name} vào giỏ hàng`);
              }}
              key={item.name}
            >
              <ShoppingCart className="text-xl" />
            </Button>
          </div>
        )}

        <div>
          <div className="flex items-center justify-between py-3 border-b-[0.5px] border-[#E5E7EB]">
            <div className="text-sm text-neutural-medium">Tên sản phẩm</div>
            <div className="font-semibold">{item.name}</div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between py-3 border-b-[0.5px] border-[#E5E7EB]">
            <div className="text-sm text-neutural-medium">Số lượng còn lại</div>
            <div
              className={classNames(
                "text-info-default bg-blue-50 py-[2px] px-2 rounded-lg font-medium",
                {
                  "bg-negative-light text-negative-default": item.stock <= 0,
                }
              )}
            >
              {item.stock > 0 ? item.stock : "Đã hết hàng"}
            </div>
          </div>

          {item.specs.slice(0, item.specs.length - 1).map((i, index) => (
            <div
              key={index}
              className={`flex justify-between py-3 ${
                index === item.descriptions.length - 3
                  ? ""
                  : "border-b-[0.5px] border-[#E5E7EB]"
              }`}
            >
              <div className="text-sm text-neutural-medium">{i.key}</div>
              <div className="text-sm w-40 text-right text-neutural-strong flex justify-end">
                {i.value}
              </div>
            </div>
          ))}
        </div>
      </div>

      {extra ? extra : null}
    </MotionDiv>
  );
};

const ProductCard = ({ item }: Props) => {
  const { addItem } = useCart();

  return (
    <MotionDiv
      className={`overflow-hidden h-full`}
      layoutId={`product-${item.model}`}
    >
      <Product
        item={item}
        onAddToCart={(i) =>
          addItem({
            id: i.name,
            price: i.price,
            name: i.name,
            stock: i.stock,
            model: i.model,
          })
        }
        onCheckout={() => {}}
      />
    </MotionDiv>
  );
};

// export const ProductLoadingCard = () => {
//   return (
//     <div className="max-w-sm p-6 bg-white rounded-3xl">
//       <div className="flex items-center justify-between">
//         <Skeleton.Avatar
//           active
//           size={80}
//           shape="square"
//           style={{ borderRadius: 24 }}
//         />
//         <div className="text-right">
//           <>
//             <Skeleton.Input
//               style={{ width: 80 }}
//               active
//               size="small"
//               className="rounded-xl"
//             />
//             <br />
//             <Skeleton.Input
//               style={{ width: 100 }}
//               active
//               size="default"
//               className="rounded-xl"
//             />
//           </>
//         </div>
//       </div>

//       <div className="flex gap-3 my-8">
//         <Skeleton.Button
//           active
//           size="large"
//           shape="default"
//           block
//           className="rounded-xl"
//           style={{ height: 48 }}
//         />
//       </div>

//       <div>
//         <div className="flex items-center justify-between py-3 border-b-[0.5px] border-[#E5E7EB]">
//           <Skeleton.Input
//             style={{ height: 28 }}
//             active
//             size="small"
//             className="rounded-xl w-full"
//           />
//         </div>

//         {[
//           {
//             title: "Quốc gia",
//             value: "Vietnam",
//           },
//           {
//             title: "Bạn bè",
//             value: "0 - 70",
//           },
//           {
//             title: "Năm tạo",
//             value: "2024",
//           },
//           {
//             title: "Gợi Ý Kết Bạn",
//             value: "Có",
//           },
//           {
//             title: "Định dạng",
//             value: "Tên đăng nhập, mật khẩu, khoá 2FA, email, mật khẩu email",
//           },
//         ].map((i, index) => (
//           <div
//             key={index}
//             className={`flex justify-between py-3 ${
//               index === 4 ? "" : "border-b-[0.5px] border-[#E5E7EB]"
//             } items-center`}
//           >
//             <Skeleton.Input
//               style={{ height: 20 }}
//               active
//               size="small"
//               className="rounded-xl w-full"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export default ProductCard;
